'use client'

import { cn } from '@/lib/utils'
import { signOut } from 'next-auth/react'

interface Props {
  className?: string
}

export const LogoutButton = ({ className }: Props) => {
  return (
    <div className={cn('flex flex-col items-end', className)}>
      <button className="w-full text-start" onClick={() => signOut({ redirectTo: '/' })}>
        Sign Out
      </button>
    </div>
  )
}
