'use client'

import { resetDemoDataAction } from '../../actions/demoEnv/reset'
import { useServerAction } from '@/app/report/lib/hooks/useServerAction'

export const useResetDemoData = () => {
  const { mutate: resetDemoData, isPending: isResetting } = useServerAction(resetDemoDataAction, {
    successText: 'Data reset successfully',
    errorText: 'Error resetting demo data',
    onSuccess: () => {
      // refresh the page to fetch the new data
      window.location.reload()
    },
  })

  return {
    resetDemoData,
    isResetting,
  }
}
