import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border border-slate-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 dark:border-slate-800 dark:focus:ring-slate-300',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-slate-900 text-slate-50 hover:bg-slate-900/80 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/80',
        secondary:
          'border-transparent bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80',
        destructive:
          'border-transparent bg-red-500 text-slate-50 hover:bg-red-500/80 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/80',
        warning:
          'border-transparent bg-amber-500 text-white hover:bg-yellow-500/80 dark:bg-yellow-900 dark:hover:bg-yellow-900/80',
        sky: 'border-transparent bg-sky-500 text-slate-50 hover:bg-sky-500/80 dark:bg-sky-900 dark:text-slate-50 dark:hover:bg-sky-900/80',
        success:
          'border-transparent bg-green-600 text-slate-50 hover:bg-green-500/80 dark:bg-green-900 dark:text-slate-50 dark:hover:bg-green-900/80',
        burgundy:
          'border-transparent bg-red-800 text-slate-50 hover:bg-red-800/80 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/80',
        outline: 'bg-white text-slate-950 dark:text-slate-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export type BadgeVariant = VariantProps<typeof badgeVariants>['variant']

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  noHover?: boolean
}

function Badge({ className, variant, noHover, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant }), className, {
        'pointer-events-none': noHover,
      })}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
