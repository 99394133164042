'use client'

import { LoadingButton } from '@/components/ui/Button'
import { useResetDemoData } from './hooks'
import { TriggerConfirmationDialog } from '@/components/ui/ConfirmationDialog'

export const ResetDemoDataButton = () => {
  const { resetDemoData, isResetting } = useResetDemoData()

  return (
    <TriggerConfirmationDialog
      title="Reset database to the demo state?"
      description={
        <>This action will reset the database by dropping all changes and re-creating the initial demo data.</>
      }
      trigger={
        <LoadingButton loading={isResetting} variant="outline">
          Reset Demo Data
        </LoadingButton>
      }
      onConfirm={resetDemoData}
    />
  )
}
