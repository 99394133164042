'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react'
import SuperJSON from 'superjson'
import { getWindow } from '@/lib/utils'
import { trpc } from './webClient'
import { useSession } from 'next-auth/react'

export const TrpcProvider = ({ children }: PropsWithChildren) => {
  const { status } = useSession()

  // Don't run queries if not authenticated or if the browser is unloading the page
  const isAuthenticated = status === 'authenticated'
  const isUnloading = useRef(false)
  const skipQueries = !isAuthenticated || isUnloading.current

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            enabled: !skipQueries,
          },
        },
      }),
    [isAuthenticated],
  )

  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        links: [
          httpBatchLink({
            url: `${getWindow()?.location.origin}/api/trpc`,
            transformer: SuperJSON,
          }),
        ],
      }),
    [],
  )

  useEffect(
    function checkIsUnloading() {
      const handleBeforeUnload = () => (isUnloading.current = true)

      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    },
    [queryClient],
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
