import { useMutation } from '@tanstack/react-query'
import { toast, ToasterToast } from '@/components/ui/useToast'
import type { AuthenticatedActionResult } from '@/app/lib/actions/utils'

/**
 * A React Query mutation wrapper for server actions.
 * Displays an error toast if the response contains an `error` property, otherwise displays a success toast.
 *
 * @param action - The server action to use in the mutation
 * @param options - Configure how the success/error toasts are displayed
 * @returns A `useMutation()` hook for the server action
 */
export function useServerAction<
  Action extends (actionInput: any) => AuthenticatedActionResult<Awaited<ReturnType<Action>>>,
  Result = Exclude<Awaited<ReturnType<Action>>, { error: string }>,
>(
  action: Action,
  {
    successText,
    errorText,
    noToast,
    onSuccess,
    onError,
  }: {
    successText?:
      | string
      | ((res: Result, variables: Parameters<Action>[0]) => Pick<ToasterToast, 'description' | 'title' | 'variant'>)
    errorText?: string
    noToast?: boolean
    onSuccess?: (res: Result, input: Parameters<Action>[0]) => void
    onError?: (error: Error, input: Parameters<Action>[0]) => void
  } = {},
) {
  const _toast = noToast ? () => {} : toast

  const mutation = useMutation({
    mutationFn: (arg: Parameters<Action>[0] extends undefined ? void : Parameters<Action>[0]) => action(arg),
    onSuccess: (data, variables) => {
      if (data && 'error' in data) {
        _toast({
          variant: 'destructive',
          title: errorText ?? 'Error',
          description: data.error,
          className: 'whitespace-pre-wrap',
        })
        onError?.(new Error(data.error), variables)
      } else {
        const {
          title,
          description,
          variant = 'success',
        } = typeof successText === 'function'
          ? successText(data as Result, variables)
          : { title: 'Success', description: successText, variant: 'success' }

        _toast({ variant, title, description, className: 'whitespace-pre-wrap' })
        onSuccess?.(data as Result, variables)
      }
    },
    onError: (error, variables) => {
      _toast({ variant: 'destructive', title: 'Error', description: error.message, className: 'whitespace-pre-wrap' })
      onError?.(error, variables)
    },
  })

  return mutation
}
